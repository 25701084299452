.Container {
    background-color: transparent !important;
    border: 2px solid #475472 !important;
    margin: 10px;
    padding: 10px;
}

.Container p {
    white-space: pre-wrap;
    text-align: left;
    font-size: 16px;
}
