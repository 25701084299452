.App {
    text-align: center;
}

.App-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px !important;
}

.App-header h1 {
    justify-self: center;
    margin: 0;
}

.Component {
    background-color: transparent !important;
    margin: 10px;
    padding: 10px;
}
